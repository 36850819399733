import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { handleAuthentication } from "../utils/auth";
import { navigate } from "gatsby";

const Auth0CallbackPage = () => {
  handleAuthentication(() => navigate("/"));

  const style = {
    textAlign: "center",
    marginTop: 300
  };
  return (
    <div style={style}>
      <ClipLoader sizeUnit="px" size={150} style={{ marginTop: 100, marginLeft: 100 }} />
    </div>
  );
};

export default Auth0CallbackPage;
